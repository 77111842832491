/* HEADER */
body {
    position: relative;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
}

.main-wrapper{
  background-color: #ffffff;
  margin-top: 40px;
  margin-bottom: 100px;
  position: relative;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
}

.top-icon-1{
    position: absolute;
    right: 19px;
    top: -40px;
    z-index: -1;
}

.top-icon{
    position: absolute;
    right: -88px;
    top: -60px;
    z-index: -1;
}

@media screen and (min-width:768px){
    .main-wrapper{
      padding:70px;
    }
}

.flag {
    position: absolute;
    left: -11px;
    top: -2px;  
}

@media screen and (min-width:768px){
    .site-logo{
        position: absolute;
        right: 100px;
        top: 20px;
    }
}

@media screen and (max-width:767px){
    .site-logo{
        img {
            margin: 30px auto;
        }
    }

    .site-title {
        text-align: center;
    }
}

h1.site-title {
  color: #42153e;
  font-size: 38px;
  font-weight: 400;
}

.header-text {
  color: #4d4d4d;
  line-height: 24px;
}

.right-header-img {
  margin-top: 25px;
}

@media screen and (max-width:991px){
    .right-header-img {
        margin: 0 auto;
    }
}