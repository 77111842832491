/*TABLE*/

@media screen and (min-width:768px){
    padding: 0px 30px 15px 30px;
}

.table-casinos {
    background: #fcfcfc;
    margin: 30px 0;

    caption {
      text-align: center;
      margin: 15px 0;
      font-size: 32px;
      color: #42153e;
    }
}

.table thead {
    background: #a3779f;
    text-align: center;
    color: #404040;
    font-size: 16px;
    font-weight: 600;
}

.table tbody tr td {
    transition: all 0.3s ease;

    &:first-child span {
        color: #4d4d4d;
        font-size: 15px;
        font-weight: 600;
    }
    text-align: center;

    img {
        margin: 0 auto;
    }
}

.top-casinos tr {
    transition: all 0.3s ease;
}

.table-hover>tbody>tr:hover {
    background: #ebf5f8
}


.top-casinos tr:hover td:first-child span {
    color: #42153e;
}

.table>tbody>tr>th,
.table>tfoot>tr>th, 
.table>tbody>tr>td,
.table>tfoot>tr>td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: middle;
    border-top: 1px solid #ededed;
}

.table-bordered>tbody>tr>th, 
.table-bordered>tfoot>tr>th, 
.table>tbody>tr>td,
.table-bordered>tfoot>tr>td {
    border: 1px solid #ededed;
}

.table > tbody > tr > td:first-child {
    border-right-width: 0px;
    width:30px;
}

.table > tbody > tr > td:nth-child(2) {
    border-left-width: 0px;
}


.table-bordered>thead>tr>td {
    border-width: 0;
}

@media screen and (min-width:768px){
    .table td.bonus-shadow {
        width: 126px;
        padding: 8px 5px;
        text-align: center;
    }

    .leftOfFeatured  { 
        background-image: url(../img/shadow-left.png);  
        background-repeat: repeat-y; 
        background-position: right -11px center;
        text-align: center;
    }
    .rightOfFeatured { 
        background-image: url(../img/shadow-right.png); 
        background-repeat: repeat-y; 
        background-position: left -7px center;
    }

    .table thead td.bonus-shadow{
        position: relative;
    }

    .table thead td.rightOfFeatured { 
        background-image: url(../img/shadow-righ-2t.png); 
        background-repeat: repeat-y; 
        background-position: left -8px center;
    }

    .table thead td.leftOfFeatured  {
        background-image: url(../img/shadow-left-2.png);  
        background-repeat: repeat-y; 
        background-position: right -7px center;
        text-align: center;
    }

    .table thead td.bonus-shadow:before {
        content: "";
        position: absolute;
        bottom: 100%;
        height: 14px;
        width: 123%;
        left: -16px;
        /* border-radius: 5px 5px 0 0; */
        background-image: url(../img/top-head.png);
        background-repeat: no-repeat;
        min-width: 158px;
    }
}

thead .bonus-shadow{
    color: #404040;
    font-size: 18px;
    font-weight: 600;
}

.bonus-shadow {
    color: #e0631a;
    font-size: 18px;
    font-weight: 600;
}

.bonus-shadow.last {
    position: relative;
}

.bonus-shadow.last:after{
    content: "";
    position: absolute;
    bottom: -35%;
    height: 32px;
    width: 113%;
    left: -16px;
    background-image: url(../img/bottom-head.png);
    background-repeat: no-repeat;
    min-width: 158px;
}

.rightOfFeatured.four-column {
    text-align: center;

    i{
        color: #c23232;
    }
}

.button {
    display: inline-block;
    border-radius: 5px;
    margin-right: 3px;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    /*background: #a02929;*/
    background: linear-gradient(to bottom,#c5132e,#b51029,#9e0e24);
    transition: all 0.3s ease;
}

.button:hover {
    opacity: .9;
    color: #fff;
    text-decoration: none;
}

@media screen and (min-width:768px){
    .button {
        width: 110px;
        height: 44px;
        line-height: 47px;
    }
}

@media screen and (max-width:767px){
    .button {
        font-size: 12px;
        padding:5px 5px;
    }
}

/*SLIDER*/
.slider {
    border: 1px solid #e0631a;
    text-align: center; 

    .right-half{
        padding: 30px 0;
    }

    .left-half{
        padding: 30px 0;

        img {
            margin: 40px 0;
        }
    }

    h2.slider-title{
        background: #e0631a;
        margin-top: 0;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-size: 26px;
        padding: 10px 0;
        font-weight: 400;
    }

    .slide-item-title{
        color: #4d4d4d;
        font-size: 22px;
        margin: 15px 0;
        font-weight: 400;
    }
    
    .slide-item-sub-title{
        color: #42153e;
        font-size: 22px;
    }
}

.carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10%;
    opacity: .5;
    filter: alpha(opacity=50);
    font-size: 20px;
    color: #fff;
    text-align: center;
    text-shadow:0;
    background-color: none;
}

.carousel-control.left,
.carousel-control.right {
    background-image: none;

    i {
        position: absolute;
        top: 50%;
        margin-top: -15px;
        color: #e0631a;

    }
}

/*CONTENT*/
.content {
  
  h2 {
    color: #42153e;
  }

  p {
    color: #4d4d4d;
  }
}

/*Quote (after content)*/
.quote {
  border-top: 1px solid #decbf0;
  padding: 15px 40px;  
  background: #f4e7f3;

  h2 {
    color: #42153e;
  }

  p {
    color: #4d4d4d;
  }
}


.social-list li {
    padding: 0 10px; 
}
.site-logo {
    font-size: 26px;
    color: #424141;
}

@media screen and (min-width:768px){
    .carousel-inner {
        height: 280px;
        max-height: 350px; 
    }
}